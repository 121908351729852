<template>
  <div class="pop" >
    <div class="popWrap" style="width: 100%;max-width: 100%;  height: 100%;padding:0;">

      <div class="box video" style="position: relative; z-index:6; width: 100%; height: 100%;padding:0; background-color: black"  ref = "videoDiv">
        <WebRTC_WOWZA
            ref="video"
            :isLiveButtonShow="true"
            :sdpUrl="channelInfo.streamServer.properties.sdpUrl"
            :applicationName="channelInfo.streamServer.properties.application"
            :streamName="channelInfo.streamKey"
            :deviceType="channelInfo.assignedDeviceType"
            @isLoadedmetadata="playerLoadData"
        />
<!--        <videoPlayer ref="video" :src="viewSrc" :isVod="false" :isAuto = "true"  :isLiveButtonShow="true" :isControls="false" @isReadyPlay="playerReady" ></videoPlayer>-->
      </div>

      <div class="box map-box" id="map-box">
        <Map ref="map" style="width: 100%; height:100%; z-index: 2; position: absolute;" :isRightLngLat="true" :loadMapName="loadMap" @play-marker-lng-lat="playMarkerLngLat" @map-is-follow="onMapIsFollow" @ready-map = "onReadyMap"/>

        <div class="box mapVodInfo" >
          <div v-if="isShow" class="drone-info">
            <div class="flexB droneVal">
              <div class="droneData">
                <span class="bold DataName">고도</span>
                <span class="vodData">{{ location.height == null ? 'N/A' : `${location.height.toFixed(0)} m` }}</span>
              </div>
              <div class="droneData">
                <span class="bold DataName">거리</span>
                <span class="vodData">{{ location.distance == null ? 'N/A' : `${location.distance.toFixed(0)} m` }}</span>
              </div>
              <div class="droneData">
                <span class="bold DataName">수평</span>
                <span class="vodData">{{location.hspeed == null ? 'N/A' :  `${location.hspeed.toFixed(0)} m/s`}}</span>
              </div>
              <div class="droneData">
                <span class="bold DataName">수직</span>
                <span class="vodData">{{ location.vspeed == null ? 'N/A' : `${location.vspeed.toFixed(0)} m/s` }}</span>
              </div>
            </div>
            <div class="flex droneCoordinate">
              <div class="droneData flex">
                <span class="DataName bold">위도</span>
                <span class="vodData">{{lat == null ? 'N/A' : `${lat.toFixed(9)}`}}</span>
              </div>
              <div class="droneData flex">
                <span class="DataName bold">경도</span>
                <span class="vodData">{{lng == null ? 'N/A' : `${lng.toFixed(9)}`}}</span>
              </div>
              <div class="droneData flex" style="justify-content: flex-start !important;" :style="accountId == 'komipo'? 'display: visible':'display: none'">
                <img src="@/assets/images/icon_svg/ic_heart_rate.svg" style="width: 2rem; height: 2rem;"/>
                <span class="small vodData" style="line-height: 1.8rem;">{{ doneData.heartRate == null ? 'N/A' : `${doneData.heartRate} bpm` }}</span>
              </div>
            </div>

            <!-- 값이 있으면 true 없으면 false-->
            <div class="live-station-info" v-if="false">
              <span class="DataName">스테이션</span>
              <span class="vodData">-</span>
            </div>
          </div>
        </div>
      </div>

      <div class="vod-info" id="vod-info">
        <div class="info-top">
          <span class="vod-pointer" v-if="channelIndex" >{{ channelIndex }}CH</span>
          <span class="vod-pointer" v-else>{{ channelInfo.name }}</span>
          <div class="info-region">
            <h2>{{ channelInfo.eventTitle }}</h2>
            <div class="info-Address">
              <span v-if="channelInfo.channelId">{{channelInfo.channelId }}</span>
              <span>{{channelInfo.assignedUserName }}</span>
              <span>{{channelInfo.assignedDepartmentName}}</span>
              <span>{{moment(channelInfo.assignedDate).format("YYYY.MM.DD HH:mm") }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="live-center-info" id = "live-center-info">
        <span>{{channelInfo.assignedDeviceName == null ? '휴대폰' : channelInfo.assignedDeviceName }}</span>
        <span>│</span>
        <span>{{channelInfo.assignedUserName }}</span>
      </div>
    </div>
  </div>
</template>
<style>
.mapVodInfo{
  width: 100%;
  position: absolute;
  padding: 5px 10px;
  z-index: 10;
  left: 50%;
  bottom: -60px;
  transform: translate(-50%);
  border-radius: 0;
  background: #232323;
}
.mapVodInfo > .drone-info > .droneVal{display: flex;align-items: center;justify-content: space-between;margin-bottom: 10px;}
.mapVodInfo > .drone-info > .droneVal > .droneData{display: flex;justify-content: space-between;align-items: center;width: 25%;}
.mapVodInfo > .drone-info > .droneVal > .droneData > .DataName{color: #969696;}
.mapVodInfo > .drone-info > .droneVal > .droneData > .vodData{color: #fff;font-size: 1.2rem;}

.droneCoordinate{display: flex;align-items: center;justify-content: space-between;margin-bottom: 5px;}
.droneCoordinate > .droneData{display: flex;justify-content: space-between;align-items: center;width: 50%;}
.droneCoordinate > .droneData > .DataName{color: #969696;}
.droneCoordinate > .droneData > .vodData{color: #fff;font-size: 1.2rem;}

.droneData{
  margin-left: 10px;
}
.droneData:nth-child(1){
  margin-left: 0;
}
.vodData{
  color: #000;
  font-size: 1.2rem;
  margin-left: 5px;
}
.DataName{
  color: #6ebeff;
  font-size: 1.2rem;
}
/*사진캡처 버튼*/
.imgCapt{
  width: 96px;
  height: 36px;
  text-align: center;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.5);
  background: white;
  border-radius: 4px;
  position: absolute;
  z-index: 2000;
  margin: 10px;
  padding: 6px 10px;
  color:black;
  border: 1px solid white;
}
.imgCapt>span{
  line-height: 23px;
  font-weight: bold;
  font-size: 1.2rem;
}
.imgCapt:hover{
  background: #94d9ef;
}

.vod-info{position: absolute;top: 9px;left: 80px;z-index: 1001;background: #3c3c3ccc;border-radius: 5px;padding: 5px 10px;}

.info-top{display: flex;justify-content: space-between;align-items: center;}
.info-top > .info-region{margin-bottom: 0px;display: flex;align-items: center;margin-left: 10px;}
.info-top > .info-region > h2{color: #fff;font-size: 1.4rem;margin-bottom: 0px;}
.info-top > .info-region > .info-Address{color: #dddddd;margin-left: 10px;margin-left: 40px;}
.info-top > .info-region > .info-Address > span{border-right: 1px solid #ddd;margin-right: 10px;padding-right: 10px;}
.info-top > .info-region > .info-Address > span:nth-child(4){border-right: 0px solid #ddd;}
.info-top > .vod-pointer{background: #232323;border-radius: 10px;padding: 4px 10px;font-size: 1.2rem;color: #fff;}

.video-player .video-js {
    width: 100%;
    height: 100vh;
    padding-top: 0%;
}
.map-box{
  position: absolute;
  bottom: 60px;
  left: 20px;
  width: 400px;
  height:300px;
  position: absolute;
  z-index: 1000;
  padding: 0;
}

.model-info{display: flex;justify-content: flex-start;align-items: center;border-bottom: 1px solid #636363;padding: 5px 0 10px 0;margin-bottom: 10px;}
.model-info > .model-img{background: #000;border-radius: 5px;width: 60px;height: 40px;text-align: center;}
.model-info > .model-img > img{width: 80%;padding-top: 8px;}
.model-info > .model-name{margin-left: 10px;}
.model-info > .model-name > h2{font-size: 1.6rem;color: #fff;margin-bottom: 3px;}
.model-info > .model-name > h4{font-size: 1.2rem;color: #b9b9b9;font-weight: 400;}

.vod-ai-mark{position: absolute;z-index: 100;right: 105px;top: 10px;background: #0b0a11b5;padding: 11px 10px;display: flex;justify-content: space-between;align-items: center;border-radius: 5px;}
.vod-ai-mark > .vod-ai-btn{display: flex;align-items: center;}
.vod-ai-mark > .vod-ai-btn > label{font-size: 1.2rem;color: #fff; margin-right: 10px;}
.vjs-fullscreen > #info-live-root > img {
    width: 6rem;
}
.live-center-info{position: absolute;top: 10px;left: 50%;transform: translate(-50%, 0%);background: #1010108a;padding: 6px 20px;color: #fff;border-radius: 5px;z-index: 100;}
.live-center-info > span{font-size: 1.4rem;}

.live-station-info{border-top: 1px solid #707070;padding-top: 5px;display: flex;justify-content: space-between;align-items: center;}
.live-station-info > .DataName{color: #969696;}
.live-station-info > .vodData{color: #fff;}
</style>
<script>
import moment from "moment";

// import videoPlayer from "@/components/video/videoPlayer";
import WebRTC_WOWZA from "@/components/video/WebRTC_WOWZA";

import Map from "@/components/map/Map";
import MapDataType from "@/components/map/enum/MapDataType";
import MapMarkerData from "@/components/map/data/MapMarkerData";
import MapLineData from "@/components/map/data/MapLineData";

import { fetchEvent } from "@/api/event";
import { fetchChannel,fetchChannelList, getLiveUrl, getDeviceTypeName } from "@/api/channel";
import { fetchPointList, getPointTypeName } from "@/api/point";
import { fetchLocation} from "@/api/location";
import {mapState} from "vuex";

getLiveUrl;

export default {
  components: { Map,
    WebRTC_WOWZA,
    // videoPlayer,
  },
  data() {
    return {
      moment: moment,
      viewSrc: "",
      channelId: "",
      liveName: "",
      channelInfo: {},
      eventInfo: {},
      height : 0,
      isLocationReq : false,
      loadMap : 'kakao',
      isFollow : true,
      accountId: null,

      lat : null,
      lng : null,
      isShow : false,
      location: [],

      doneData : {
        distance : null,
        hspeed : null,
        vspeed : null,
        height : null,
        battery : null,
        heartRate: null,
      },
    };
  },
  computed: {
    ...mapState({currentLang: state => state.store.currentLang}),
  },
  watch : {
    currentLang(value){
      this.initMapData(value);
    },
  },
  created() {
    this.accountId = localStorage.getItem("accountId");
  },
  mounted() {
    this.channelId = this.$route.query.channelId;
    this.channelIndex = this.$route.query.channelIndex;
    this.isShow = true;
    if (this.channelId) {
      this.getLiveDetailByChannelId();
    }

    this.interval = setInterval(() => {
      this.updateMapPosition();
    }, 2000);
    this.lineInterval = setInterval( () => {
      this.updateMapLine()
    }, 2000);

  },
  methods: {
    onReadyMap(isRead){
      if(isRead) {
        this.setMapSetting()
      }
    },
    getMapMarker(){
      if(this.channelInfo == null) {
        return null;
      }

      return this.channelInfo.assignedDeviceName == null ? MapDataType.ID_STREAMER : MapDataType.ID_DRONE
    },
    onMapIsFollow(value){
      this.isFollow = value;
    },
    initMapData(value){
      if(value == 'en') {
        this.loadMap = 'google'
      } else {
        this.loadMap = 'kakao'
      }
      this.getEventPoint();
    },
    setMapSetting(){
      //this.$refs.map.onShowLngLat();

      this.$refs.map.onShowButtonTypeChange();
      this.$refs.map.onShowButtonFollow();
      this.$refs.map.onClickShowLine(true);
      this.$refs.map.onSetZoomLevel(6);
    },
    playMarkerLngLat(lng,lat,index) {
      this.$refs.map.onSetLngLat(lng,lat);
      if(this.location.length > 0 ) {
        let locationData = this.location[index];
        this.doneData.distance = locationData.distance;
        this.doneData.hspeed = locationData.hspeed;
        this.doneData.vspeed = locationData.vspeed;
        this.doneData.height = locationData.height;
        this.doneData.battery = locationData.battery;
        this.doneData.heartRate = locationData.heartRate;
        this.lng = lng;
        this.lat = lat;
      }
    },
    getDeviceTypeName(type){
      return getDeviceTypeName(type);
    },
    async getEventPoint() {
      let params = {
        eventId : this.eventInfo.eventId,
        serverTime : null,
      };
      this.$refs.map.onClearMapData();
      this.$refs.map.setLayout();
      await fetchPointList( params).then((res) => {
        if (res.data.result == 0) {
          this.pointServerTime = res.data.data.serverTime
          this.pointList = res.data.data.content
          this.pointCount = res.data.data.total
          this.pointList.forEach((element) => {
            element.selectId = element.index;
            element.selectType = "Point";
            element.isOwner = (this.isAdmin || this.userId == element.registerUserId);
            let pointMapMarker = new MapMarkerData();
            element.name = this.$t(getPointTypeName(element.type));
            pointMapMarker.setPointData(element);
            this.$refs.map.onCreateMarker(MapDataType.ID_POINT, pointMapMarker);
          })
        }
      });
    },
    async mapSetCenter() {
      await fetchEvent(this.channelInfo.eventId).then((res) => {
        this.eventInfo = res.data.data;
        if (res.data.result == 0) {

          if(res.data.data.lng != null && res.data.data.lng != "" && res.data.data.lat != null && res.data.data.lat != "") {
            this.$refs.map.onLngLatToMoveCenter(res.data.data.lng, res.data.data.lat)
          } else {
            this.$refs.map.onAddressToMoveCenter(res.data.data.address);
          }
          this.initMapData(this.$store.state.store.currentLang);
        }
      });
    },

    async updateMapPosition() {
      if(this.channelId == null) {
        return;
      }

      let params = {
        pageNumber: 0,
        channelId : this.channelId,
      };

      await fetchChannelList(params).then((res) => {
        let content = res.data.data.content;
        if(content.length > 0) {
          var channelData = content[0];
          this.channelToMapMarker(channelData);
        }
      });
    },
    channelToMapMarker(channelData){
      let mapDataType = this.getMapMarker();
      if(mapDataType == null) {
        return;
      }
      this.$refs.map.onSetIsShowDataType(MapDataType.ID_STREAMER, true);
      this.$refs.map.onSetIsShowDataType(MapDataType.ID_DRONE, true);
      let mapMarker = new MapMarkerData();
      mapMarker.id = channelData.channelId;
      mapMarker.mapDataType = mapDataType;
      mapMarker.name = mapDataType == MapDataType.ID_STREAMER ? channelData.assignedUserName : channelData.assignedDeviceName;
      mapMarker.lng = channelData.lng;
      mapMarker.lat = channelData.lat;
      mapMarker.rotate = channelData.azimuth;
      mapMarker.gimbalDirection = channelData.gimbalDirection;
      // mapMarker.isMain = true;
      this.$refs.map.onSetLngLat(channelData.lng, channelData.lat);
      if(this.$refs.map.onIsMarker(mapDataType, channelData.channelId ) ) {
        this.$refs.map.onModifyMarkerAt(mapDataType, mapMarker);
      } else {
        this.$refs.map.onCreateMarker(mapDataType, mapMarker);
      }
    },
    getLiveDetailByChannelId() {
      fetchChannel(this.channelId).then((res) => {
        this.channelInfo = res.data.data;
        this.viewSrc = getLiveUrl(this.channelInfo);
        this.mapSetCenter();
      });
    },
    getChannelRefId(channel){
      var refId = null;
      if(channel.assignedDeviceType == "Mobile") {
        refId = channel.assignedWorkerId;
      } else {
        refId = channel.assignedDeviceLogId;
      }
      return refId;
    },
    updateMapLine(){
      if(this.channelInfo.channelId == undefined) {
        return;
      }

      var refId = this.getChannelRefId(this.channelInfo)
      if(refId != null) {
        this.getLocation(refId)
      }
    },
    getLocation(refId){
      if(this.isLocationReq == true) {
        return;
      }
      this.isLocationReq = true;
      let mapDataType = this.getMapMarker();
      if(mapDataType == null) {
        return;
      }

      let offset = this.$refs.map.onGetLineSize(mapDataType, refId);
      let params = {
        refId: refId,
        eventId: this.eventId,
        offsetIndex: offset,
      };
      fetchLocation(params).then((res) => {
        this.isLocationReq = false;
        if (res.data.result == 0) {
          if (res.data.data.content.length > 0) {
            res.data.data.content.sort(function (a, b) {
              return a.index - b.index;
            })
            let mapDataType = this.getMapMarker();
            if(mapDataType == null) {
              return;
            }

            this.location = res.data.data.content[res.data.data.content.length - 1];
            this.changeMapLatLng(this.location.lat, this.location.lng);
            this.createMapLine(mapDataType, refId, res.data.data.content);
          }
        }else{
          this.location = [];
        }
      }).catch((e)=>{
        e;
        this.isLocationReq = false;
      });

    },
    changeMapLatLng(lat, lng){
      if(lat == null || lat == undefined || lng == null || lng == undefined) {
        return;
      }
      this.lng = lng;
      this.lat = lat;

      if(this.isFollow){
        this.$refs.map.onLngLatToMoveCenter(this.lng, this.lat);
      }
      //this.$refs.map.onSetLngLat(lat,lng);
    },
    createMapLine(mapDataType, id, locationGroup) {
      var mapLineData = new MapLineData();
      mapLineData.id = id;
      mapLineData.locationGroup = locationGroup;
      mapLineData.isMain = true;
      if (this.$refs.map.onIsLine(mapDataType, id)) {
        this.$refs.map.onModifyLineAddAt(mapDataType, mapLineData);
      } else {
        this.$refs.map.onCreateLine(mapDataType, mapLineData);
      }
    },
    playerLoadData(id, isLoad){
      if(isLoad == true) {

        console.log(this.$refs.video)
        this.$refs.video.onPlayerAddDom("map-box");
        this.$refs.video.onPlayerAddDom("vod-info");
        this.$refs.video.onPlayerAddDom("live-center-info");
      }
    },
    playerReady(player){
      player
       this.$refs.video.onPlayerAddDom("map-box");
       this.$refs.video.onPlayerAddDom("vod-info");
       this.$refs.video.onPlayerAddDom("live-center-info");
    },
  },
  beforeDestroy() {
    clearInterval(this.interval);
    clearInterval(this.lineInterval);
  },
};
</script>
